import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {

    resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Worli = () => {
    const [roundId, setRoundId] = useState('')
    const defaultValues = {"status": 'suspended-box', "odds": ''};
    const [events, setEvents] = useState('')

    const [totalPlayers, setTotalPlayers] = useState({

        SINGLE: {
            "name": Array.from({length: 10}, (_, index) => {
                return index + " Single"
            }),
            ...defaultValues
        },

        LINE: {
            "name": ["Line 1 Single", "Line 2 Single"],
            ...defaultValues
        },
        EVEN_ODD: {
            "name": ["Odd", "Even"],
            ...defaultValues
        },


    })


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {

        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {

        if (data?.sub) {
            setTotalPlayers((prevState) => {
                const prevPlayer ={...prevState}

                const updatedPlayers = Object.entries(prevPlayer).map(([index,value], i )=> {
                    const v = {...value};
                    v.status =  data.sub[0].gstatus === 'OPEN' ? '' : 'suspended-box'
                    return [index, v];
                })
                return Object.fromEntries(updatedPlayers);


            })

        }

        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),

            });
            remark.current = data.remark || 'Welcome';
        }
    }, [data]);



    const openPopup = (isBakOrLay, teamnam, oddvalue, event) => {

        event.classList.remove('back');
        const tbodyElements = document.querySelectorAll('tbody .bg-success');
        tbodyElements.forEach(element => element.classList.remove('bg-success'));
        event.classList.add('bg-success');

        setEvents(event)
        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')
        }
    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {
        setHideLoading(false)

        const notify = (status = 'suspended') => {
            if (status === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');


            } else {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')

            }
            setPopupDisplayForDesktop(false);
            setHideLoading(true);
            setSubmitButtonDisable(false)

            resetBetFields(profitData, stakeValue)

        }


        if (totalPlayers['SINGLE'].status !== '') {
            notify()
            return;
        }


        if (roundIdSaved.current !== roundId) {
            notify('change')
            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": backOrLay === 'back' ? 1 : 0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "SINGLE",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {
                    events.classList.remove('bg-success');
                    events.classList.add('back');

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })
    }

    return (
        <CasinoLayout hideRules={true} hideLoading={hideLoading} isBack={backOrLay} teamname={teamname}
                      handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>


            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards(cards.playerA, "Player A")}
                        </div>

                    </div>
                </div>
            </div>

            <div className="table-responsive m-b-10">
                <table className={`table table-bordered worli-single-data ${totalPlayers['SINGLE'].status}`}>
                    <tbody>
                    {/* Odds row */}
                    <tr>
                        <th colSpan="5" className="text-center card-odds back"><b>9.5</b></th>
                        <th colSpan="2" className="text-center card-odds back"><b>9.5</b></th>
                    </tr>

                    {/* First Row */}
                    <tr className="back">
                        {totalPlayers['SINGLE'].name.slice(1, 6).map((value, index) => (
                            <td
                                key={index}
                                className={`text-center single${index + 1} cursor-pointer`}
                                onClick={event => openPopup('back', value, 9.5, event.currentTarget)}
                            >
                                <span className="d-block card-number">{index + 1}</span>
                            </td>
                        ))}
                        <td className="text-center line1 cursor-pointer"
                            onClick={event => openPopup('back', 'Line 1', 9.5, event.currentTarget)}>
                            <span className="d-block line-odd-even">Line 1</span>
                            <span className="d-block">1|2|3|4|5</span>
                        </td>
                        <td className="text-center oddclick cursor-pointer"
                            onClick={event => openPopup('back', 'Odd', 9.5, event.currentTarget)}>
                            <span className="d-block line-odd-even">ODD</span>
                            <span className="d-block">1|3|5|7|9</span>
                        </td>
                    </tr>

                    {/* Second Row */}
                    <tr className="back">
                        {totalPlayers['SINGLE'].name.slice(6, 11).map((value, index) => (
                            <td
                                key={index + 6}
                                className={`text-center single${index + 6}`}
                                onClick={event => openPopup('back', value, 9.5, event.currentTarget)}
                            >
                                <span className="d-block card-number">{index + 6}</span>
                            </td>
                        ))}
                        <td className="text-center line2"
                            onClick={event => openPopup('back', 'Line 2', 9.5, event.currentTarget)}>
                            <span className="d-block line-odd-even">Line 2</span>
                            <span className="d-block">6|7|8|9|0</span>
                        </td>
                        <td className="text-center evenClick"
                            onClick={event => openPopup('back', 'Even', 9.5, event.currentTarget)}>
                            <span className="d-block line-odd-even">EVEN</span>
                            <span className="d-block">2|4|6|8|0</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
            <div className="casino-last-result-title">
                <span>Last Result</span>
            </div>
            <div className="casino-last-results">
                <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
            </div>


        </CasinoLayout>
    );

};

export default Worli;
