import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    classifyCard, getExByColor, getExBySingleTeamNameCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";


import Notify from "../../js/Notify";

const Teen8 = () => {
    const [roundId, setRoundId] = useState('')


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        setBetType,
        setPopupDisplayForDesktop,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)
    const [oddsData, setOddsData] = useState({

        "Player 1": 1.98,
        "Player 2": 1.98,
        "Player 3": 1.98,
        "Player 4": 1.98,
        "Player 5": 1.98,
        "Player 6": 1.98,
        "Player 7": 1.98,
        "Player 8": 1.98,


    });

    const [data, setData] = useState([]);

    const [playerStatuses, setPlayerStatuses] = useState({
        "Player 1": 'suspended-box',
        "Player 2": 'suspended-box',
        "Player 3": 'suspended-box',
        "Player 4": 'suspended-box',
        "Player 5": 'suspended-box',
        "Player 6": 'suspended-box',
        "Player 7": 'suspended-box',
        "Player 8": "suspended-box",


    });


    const ruleImage = '/img/rules/teen20.jpg'
    const ruleDescription = '';

    const remark = useRef('Welcome');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);

    useEffect(() => {


        if (data?.sub) {


            let card = data.card.split(',')

            setCards(card);

        }

    }, [data?.sub]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;


    useEffect(() => {

        if (data?.sub && sportList?.id) {
            Object.keys(playerStatuses).forEach((team, index) => {

                updatePlayerStats(data.sub[index], team);


                getExBySingleTeamNameCasino(sportList.id, data.mid, team, match_id, 'ODDS')
                    .then(res => {
                        // Update playerAmounts based on the response
                        setPlayerAmounts(prev => ({
                            ...prev,
                            [team]: res.data // Assuming the amount is in the response
                        }));
                    })
                    .catch(error => {
                        console.error(`Error fetching data for ${team}:`, error);
                    });



            });
        }
    }, [exposure, sportLength, roundId]);

    const updatePlayerStats = (playerData, playerName) => {
        if (!playerData) return;

        let playerStatus = playerData.gstatus === "SUSPENDED" ? "suspended-box" : '';
        setPlayerStatuses(prev => ({...prev, [playerName]: playerStatus}));
    };
    const openPopup = (isBakOrLay, teamnam, oddvalue) => {


        if (parseFloat(oddvalue) > 0) {
            roundIdSaved.current = roundId
            setbackOrLay(isBakOrLay)
            setPopupDisplayForDesktop(true);
            teamname.current = teamnam
            setOdds(oddvalue)
        } else {
            Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

        }


    }
    const casinoBetDataNew = (new_odds) => {
        stakeValue.current = new_odds
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }


    const renderCards = (playerNumber) => {
        if (Object.keys(cards).length > 0) {
            return cards.map((cardObj, index) => {
                cardObj = classifyCard(cardObj)

                if (index % 9 === playerNumber - 1 && cardObj.card_no) {
                    const {card_no, card_icon, card_class} = cardObj;

                    return (

<span className="card-icon m-l-5" key={index}>
                            {card_no}
                            <span className={card_class}>{card_icon}</span>
                        </span>

                    );
                }
                return null;
            });
        }
        return null;
    };


    const renderDealerCards = (imgCardArr) => {
        if (imgCardArr && imgCardArr.length > 0) {
            return imgCardArr.map((card, index) => {
                if (index % 9 === 8 && card) { // Equivalent to PHP loop $a=8; $a=$a+9

                    return <img src={`/img/casino/cards/${card}.png`} key={index}/>;
                }
                return null;
            });
        }
        return null;
    };


    const placeBet = () => {

        setHideLoading(false)
        if (playerStatuses[teamname.current] === 'suspended-box') {
            Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
            setPopupDisplayForDesktop(false);
                            setHideLoading(true)

            return;
        }

        if (roundIdSaved.current !== roundId) {
            Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
            setPopupDisplayForDesktop(false)
                            setHideLoading(true)

            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback":0,
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": "ODDS",
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": "TEEN8",
            "matchType": "teen8",

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {

                    resetBetFields(profitData, stakeValue)
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplayForDesktop(false)
            })


    }
    const [playerAmounts, setPlayerAmounts] = useState({
        "Player 1": '',
        "Player 2": '',
        "Player 3": '',
        "Player 4": '',
        "Player 5": '',
        "Player 6": '',
        "Player 7": '',
        "Player 8": "",

    });

    return (
        <CasinoLayout hideLoading={hideLoading} isBack={backOrLay} teamname={teamname} handleStakeChange={casinoBetDataNew} odds={odds}
                      stakeValue={stakeValue} setOdds={setOdds} placeBet={placeBet}
                      submitButtonDisable={submitButtonDisable} data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '-300px'}}>

                    <div className="videoCards">
                        <div>
                            <h6 className="text-white">DEALER</h6>
                            <div>
                                {renderDealerCards(cards)}

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="casino-detail">

                <div className="table-responsive mb-1">
                    <table className="table table-bordered mb-0">
                        <thead>
                        <tr>
                            <th className="box-4 min-max"></th>
                            <th className="box-3 text-center back">
                                ODDS
                            </th>

                            <th className="box-3 text-center back">
                                Pair Plus
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(oddsData).map((player, index) => (
                            <tr key={index}>
                                <td className="box-4">
                                    <b>{player}</b>
                                    <span className="patern-name">
                                        {renderCards(index + 1)}
                                        </span>


                                </td>
                                <td className={`box-3 back text-center ${playerStatuses[player]}`} onClick={() => openPopup('back', player, oddsData[player])}>
                <span className="odds d-block">
                  <b>{oddsData[player]}</b>
                </span>
                                    <span style={{color: "black"}}>
                                            {getExByColor(playerAmounts[player])}

                </span>
                                </td>
                                <td className="box-3 back text-center suspended-box">
                <span className="odds d-block">
                  <b>Pair plus {index + 1}</b>
                </span>
                                    <span style={{color: "black"}}>0</span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            

        </CasinoLayout>
    );

};


export default Teen8;
