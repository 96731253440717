import React, {useContext, useEffect, useRef, useState} from "react";
import SportsLayout from "../Section/SportsLayout";
import {
    getExByTeamNameForCricket,
    showCricketSessionBook
} from "../../Common_Functions/Constants";

import {SportsContext} from "../../Components/Context/SportsContext";
import Bookmaker from "../Section/AllSports/Bookmaker";
import MatchOdds from "../Section/AllSports/MatchOdds";
import TiedMatch from "../Section/Cricket/TiedMatch";
import FancySession from "../Section/Cricket/FancySession";
import OverByOver from "../Section/Cricket/OverByOver";
import Meter from "../Section/Cricket/Meter";
import OddEven from "../Section/Cricket/OddEven";
import Fancy1 from "../Section/Cricket/Fancy1";
import Khado from "../Section/Cricket/Khado";
import {useNavigate, useParams} from "react-router-dom";
import BallByBall from "../Section/Cricket/BallByBall";
import {Buffer} from "buffer";
import CommonLayout from "../Section/CommonLayout";
import Scoreboard from "../Section/Cricket/Scoreboard";

const Cricket = () => {

    const [gameId, setGameId] = useState('')

    const nav = useNavigate();
    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})
    const [tiedMatchData, setTiedMatchData] = useState({})

    const [ar_sectionData, setAr_sectionData] = useState([])
    const {match_id} = useParams();
    const teamNames = useRef({});


    const defaultTeamName = useRef('')

    const [maxValue, setMaxValue] = useState([])
    const [minValue, setMinValue] = useState([])
    const currentOddValue = useRef([]);
    const allTeamName = useRef([])
    const teamNameCurrentBets = useRef({})


    const [tvTriggered, setTvTriggered] = useState(false)
    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {
        popupDisplay,
        setPopupDisplay,
        betType,
        sports_socket,
        betPlaceStatusCheck,
        setShowLoader,
        showLoader,
        betPlaceStatus,
        defaultTeamDatasCalculation
    } = useContext(SportsContext)
    const [placingBets, setPlacingBets] = useState([])
    const [sportList, setSportList] = useState({})

    const trackData = useRef({});
    const tvTrigger = async (gammmmee) => {



        try {
            setTvTriggered(true)

            sports_socket.emit('setPurposeFor', 'tv', 'cricket', '', '', gammmmee)

            // Handle the successful response here, e.g., console.log(response.data)
        } catch (error) {
            // Handle specific error scenarios
            if (error.response) {
                // Server responded with a status other than 200 range
                console.error("Error Response:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                // No response received from server
                console.error("No response received:", error.request);
            } else {
                // Something happened in setting up the request
                console.error("Error:", error.message);
            }
        }
    }

    useEffect(() => {
        sports_socket.emit('setPurposeFor', 'sports', 'cricket', '', '', match_id)
        let socket_game = `getSportDatacricket${match_id}`;

        setShowLoader(true)
        let emptyCheckTimeout;

        const gameConnect = () => {
            sports_socket.on(socket_game, sportData => {


                if (sportData !== null) {
                    sportData = JSON.parse(Buffer.from(sportData).toString('utf8'))




                    if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {
                        const gameSet = [];

                        setShowLoader(false);

                        for (let i = 0; i < sportData.game_detail.length; i++) {
                            const value = sportData.game_detail[i];

                            if (value.hasOwnProperty('gmid') && gameId === '') {

                                setGameId(value.gmid)




                            }


                            const gtype = value.mname.toLowerCase();


                            gameSet[gtype] = value;
                        }


                        if (Object.values(gameSet).length > 0 && emptyCheckTimeout) {
                            clearTimeout(emptyCheckTimeout);  // Clear the timeout if data is received
                            emptyCheckTimeout = null;
                        }

                        setAr_sectionData(gameSet)


                        trackData.current = gameSet;

                    }

                }


            })


        }

        gameConnect();


        emptyCheckTimeout = setTimeout(() => {

            if (Object.values(trackData.current).length === 0) {

                 nav('/');  // Redirect if gameSet is empty
            }

        }, 13000);

        sports_socket.on('disconnect', function () {

            // Reconnect logic
            const connectInterval = setInterval(() => {


                gameConnect();
                clearInterval(connectInterval)
            }, 1000);  // Reattempt connect every second
        });


        return () => {
            sports_socket.off(socket_game);
            setPopupDisplay(false)
            setShowLoader(false);

        };

// eslint-disable-next-line
    }, [match_id]);

    useEffect(() => {
        if(tvTriggered === false && gameId !== ''){
            console.log('tv ttrig')
             tvTrigger(gameId)
        }
        console.log("gameId is ",gameId)
    }, [gameId]);

    const oddsChange = useRef({});
    const individualBetPlaceFetch = (teamname) => {

        betPlaceStatus.current[teamname] = betPlaceStatusCheck(sportList, ar_sectionData, teamname)

    }

    useEffect(() => {
        if (Object.keys(sportList).length > 0) {

            defaultTeamDatasCalculation(sportList, setOddsTeamData, setBookmakerTeamData, setTiedMatchData)
        }

    }, [sportList]);

    const ar_lengt = Object.keys(ar_sectionData).length;


    const callTeamDatas = (betType = {
        'ODDS': 'match_odds',
        'BOOKMAKER': 'bookmaker',
        'TIED_MATCH': 'tied match'
    }) => {

        try {
            if (Object.keys(sportList).length > 0) {

                Object.entries(betType).forEach(([index, value]) => {

                    switch (index) {
                        case 'ODDS' :

                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, oddsTeamData, setOddsTeamData);
                            break;
                        case 'BOOKMAKER' :


                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, bookmakerTeamData, setBookmakerTeamData)
                            break;

                        default  :
                            getExByTeamNameForCricket(ar_sectionData, index, value, sportList.id, tiedMatchData, setTiedMatchData)

                    }


                })


            }


        } catch (error) {
            console.error("Error fetching team data:", error);
        }
    };

    const expsoure = localStorage.getItem('exposure')

    useEffect(() => {

        if (ar_lengt > 0) {

            callTeamDatas();
        }
        betPlaceStatusCheck(sportList, ar_sectionData)

        // eslint-disable-next-line
    }, [ar_lengt, expsoure]);

    return (

        <CommonLayout>
            <SportsLayout callTeamDatas={callTeamDatas}
                          setOddsTeamData={setOddsTeamData} placingBets={placingBets} setPlacingBets={setPlacingBets}
                          currentOddValue={currentOddValue} maxValue={maxValue} minValue={minValue}
                          teamNameCurrentBets={teamNameCurrentBets}
                          showCalculation={true}
                          individualBetPlaceFetch={individualBetPlaceFetch} betType={betType}
                          backOrLay={backOrLay} teamname={defaultTeamName}
                          odds={betOddValue}
                          teamNames={['ODDS', 'BOOKMAKER', 'TIED_MATCH'].includes(betType) ? allTeamName : []}
                          setOdds={setBetOddValue}
                          setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay} sportList={sportList}
                          data={ar_sectionData} setSportList={setSportList} gameId={gameId}>

                <Scoreboard/>


                {!showLoader && (
                    <>
                        <MatchOdds oddsChange={oddsChange} placingBets={placingBets} currentOddValue={currentOddValue}
                                   setMaxValue={setMaxValue} setMinValue={setMinValue}
                                   teamNameCurrentBets={teamNameCurrentBets}
                                   allTeamName={allTeamName} oddsTeamData={oddsTeamData}
                                   setDefaultTeamName={defaultTeamName}
                                   setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                                   setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                                   sportList={sportList}/>

                        <Bookmaker placingBets={placingBets} currentOddValue={currentOddValue} setMaxValue={setMaxValue}
                                   setMinValue={setMinValue} teamNameCurrentBets={teamNameCurrentBets}
                                   allTeamName={allTeamName} setDefaultTeamName={defaultTeamName}
                                   bookmakerTeamData={bookmakerTeamData} setBetOddValue={setBetOddValue}
                                   setbackOrLay={setbackOrLay}
                                   teamNames={teamNames} setPopupDisplay={setPopupDisplay}
                                   ar_sectionData={ar_sectionData}
                                   sportList={sportList} oddsChange={oddsChange}/>


                        <TiedMatch teamNameCurrentBets={teamNameCurrentBets} allTeamName={allTeamName} model={sportList}
                                   tiedMatchData={tiedMatchData} gameData={ar_sectionData} teamNameArr={teamNames}

                                   setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                                   setPopupDisplay={setPopupDisplay} setDefaultTeamName={defaultTeamName}
                        />
                        <FancySession betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                                      setPopupDisplay={setPopupDisplay} sportList={sportList}
                                      oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                                      setBetOddValue={setBetOddValue} data={ar_sectionData}
                                      showCricketSessionBook={showCricketSessionBook}
                        />
                        <OverByOver betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                                    setPopupDisplay={setPopupDisplay}
                                    model={sportList} oddsChange={oddsChange} teamNames={teamNames}
                                    setbackOrLay={setbackOrLay}
                                    setBetOddValue={setBetOddValue} gameData={ar_sectionData}
                                    showCricketSessionBook={showCricketSessionBook}/>
                        <BallByBall betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                                    setPopupDisplay={setPopupDisplay} sportList={sportList}
                                    oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                                    setBetOddValue={setBetOddValue} data={ar_sectionData}
                                    showCricketSessionBook={showCricketSessionBook}
                        />

                        <Fancy1 betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                                setPopupDisplay={setPopupDisplay} sportList={sportList}
                                oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                                setBetOddValue={setBetOddValue} data={ar_sectionData}

                        />


                        <Khado betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                               setPopupDisplay={setPopupDisplay} sportList={sportList}
                               oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                               setBetOddValue={setBetOddValue} data={ar_sectionData}
                               showCricketSessionBook={showCricketSessionBook}
                        />

                        <OddEven setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}
                                 setDefaultTeamName={defaultTeamName} model={sportList} gameData={ar_sectionData}
                                 setPopupDisplay={setPopupDisplay}/>
                        <Meter betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName}
                               setPopupDisplay={setPopupDisplay} sportList={sportList}
                               oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                               setBetOddValue={setBetOddValue} data={ar_sectionData}
                               showCricketSessionBook={showCricketSessionBook}
                        />
                    </>
                )}
            </SportsLayout>
        </CommonLayout>

    )
        ;
};
export default Cricket;