import React, {useContext, useEffect, useRef, useState} from 'react';
import axiosFetch, {setdecimalPoint} from '../Common_Functions/Constants';
import $ from "jquery";
import {useParams} from "react-router-dom";
import {SportsContext} from "./Context/SportsContext";
import Notify from "../js/Notify";
import {AuthContext} from "./Context/AuthContext";
import {BetPlacPopupSection} from "./BetPlacPopupSection";

const BetPlacePopup = ({
                           setPopupDisplay,
                           individualBetPlaceFetch,
                           odds,
                           showCalculation = false,
                           setOdds,
                           backOrLay,
                           teamNameCurrentBets = {},
                           teamNames = {},

                           data = {},

                           teamname,
                           sportList,
                           maxValue = 1,
                           minValue = 1,
                           callTeamDatas = null

                       }) => {
    const {betType, oddsk, betTypeFromArray} = useContext(SportsContext);

    useEffect(() => {

    }, [teamNames]);
    const stakeValue = useRef(0);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
    const [profitValue, setProfitValue] = useState(0)
    const {getBalance} = useContext(AuthContext);
    const [hideLoading, setHideLoading] = useState(true)
    const loss = useRef(0);
    const {match_id} = useParams();
    const profit = useRef(0);
    const profitData = useRef(0);

    const {runnerRowDefault, rootClassDefault} = useContext(SportsContext)

    const changeDataRef = useRef({})

    const handleStakeChange = (event, newodds = null) => {


        const value = event.target.value;
        stakeValue.current = value;

        $("#stakeValue").val(value)

        if (newodds !== null) {
            odds = newodds
        }

        calculateProfit(value, odds);

        const losslayvalue = ((parseFloat(odds)) * parseFloat(value)) / 100

        const lossvalue = (parseFloat(odds) - 1) * parseFloat(value)
        loss.current = backOrLay === 'back' ? parseFloat(setdecimalPoint(value)) : (betType === 'ODDS' ? parseFloat(setdecimalPoint(lossvalue)) : parseFloat(setdecimalPoint(losslayvalue)));



    };


    const calculateProfit = (stake, odds) => {
        // Parse odds and stake once for performance
        const parsedOdds = parseFloat(odds);
        const parsedStake = parseFloat(stake);

        let profitCalc = 0;

        if (betType === 'ODDS' || betType === 'ODDEVEN') {
            profitCalc = parsedStake * (parsedOdds - 1);
        } else if (betType === 'SESSION') {
            profitCalc = parsedStake;
        } else if (betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
            profitCalc = (parsedStake * parsedOdds) / 100;


        } else {
            profitCalc = parsedStake * parsedOdds;
        }

        // Ensure profitCalc is always a number to avoid issues
        profitCalc = isNaN(profitCalc) ? 0 : profitCalc;

        // Store the result directly in the profit reference
        profit.current = profitCalc.toFixed(2);


        // Handle profit/loss logic for back or lay
        if (backOrLay === 'back') {
            if (betType === 'ODDS') {
                profit.current = profitData.current = profit.current
            } else if (betType === 'BOOKMAKER') {

                profit.current = profitCalc
                profitData.current = profitCalc
            } else if (betType === 'ODDEVEN') {
                profit.current = profitData.current = profit.current
            } else if (betType === 'fancy1') {

                profit.current = profitData.current = (parsedOdds - 1) * 100;
            } else if (betType === 'TIED_MATCH') {

                profit.current = profitData.current = (parsedOdds * parsedStake) / 100;
            } else {

                profit.current = profitData.current = (oddsk.current * parsedStake) / 100;
            }
        } else {

            profitData.current = parsedStake;
            profit.current = parsedStake
        }

        setProfitValue(profitData.current)

    };


    const clearAllSelection = () => {
        stakeValue.current = 0;
        setOdds(0);
        profit.current = 0;
        loss.current = 0
    };
    useEffect(() => {
        if (Object.entries(data).length > 0) {
            changeDataRef.current = data
        }
        return () => {
            changeDataRef.current = {}
        }

    }, [data]);
    const resetAll = () => {
        stakeValue.current = ''
        profit.current = 0
        profitData.current = 0
        setPopupDisplay(false)
        setHideLoading(true)
        setSubmitButtonDisable(false)

    }

    const placeBet = async () => {

        const getLatestDataFound = () => {
            const teamm = [' - Odd', ' - Even'].some(el => teamname.current.includes(el))
                ? teamname.current.split(' - ')[0]
                : teamname.current;

            return changeDataRef.current[betTypeFromArray]?.section.find(item => item.nat === teamm);
        };
        setSubmitButtonDisable(true)
        setHideLoading(false)


        let datafound = getLatestDataFound()

        backOrLay = betType === 'ODDEVEN' ? (backOrLay === 'back' ? 'odd' : 'even') : backOrLay;
        let match_odd = datafound?.[backOrLay]?.[runnerRowDefault.current];


        const minMaxCheck = () => {
            if (maxValue?.[betType] && maxValue[betType] < stakeValue.current) {
                setHideLoading(true)

                Notify('Min Max Bet Limit Exceed', null, null, 'danger');
                resetAll()


                return false;

            } else if (minValue?.[betType] && minValue[betType] > stakeValue.current) {
                setHideLoading(true)

                Notify('Min Max Bet Limit Exceed', null, null, 'danger');
                resetAll()

                return false;

            }
            return true;

        }

        if (!minMaxCheck()) {
            return;
        }

        if (betType === 'ODDS') {

            const oddFunction = () => {
                let datafound = getLatestDataFound()

                backOrLay = betType === 'ODDEVEN' ? (backOrLay === 'back' ? 'odd' : 'even') : backOrLay;
                let match_odd = datafound[backOrLay][runnerRowDefault.current];


                if (datafound.gstatus !== 'ACTIVE' && datafound.gstatus !== '') {

                    Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                    resetAll()

                    return false;
                }
                if (match_odd !== undefined) {


                    if (backOrLay === 'back' && odds > match_odd.odds) {

                        Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                        resetAll()
                        return false;
                    }


                    if (backOrLay === 'lay' && odds < match_odd.odds) {

                        Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                        resetAll()
                        return false;
                    }
                    calculateProfit(stakeValue.current, match_odd.odds)
                    odds = match_odd.odds

                }
                return true;
            }

            const isOddValid = await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(oddFunction());
                }, 5000);


            });

            if (!isOddValid) return;

        } else if (betType === 'BOOKMAKER') {
            if (datafound && datafound?.gstatus !== 'ACTIVE' && datafound?.gstatus !== '') {

                Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                resetAll()

                return;
            }
            if (match_odd !== undefined) {

                if (odds !== match_odd.odds) {

                    Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                    resetAll()

                    return;
                }

            }
        } else {


            if (datafound.gstatus !== 'ACTIVE' && datafound.gstatus !== '') {

                Notify('Bet not Confirm. Game Suspended', null, null, 'danger');
                resetAll()

                return;
            }
            if (match_odd !== undefined) {

                if (odds !== match_odd.odds) {

                    Notify('Odds Value change, Bet not Confirm!', null, null, 'danger');
                    resetAll()

                    return;

                }

            }
        }


        const submit = () => {
            const data = {
                sportId: sportList.id,
                matchId: sportList.match_id,
                isback: backOrLay === 'back' ? 1 : 0,
                placeName: teamname?.current?.trim(),
                placeName2: '',
                odds: odds,
                oddsk: oddsk.current,
                profit: backOrLay === 'lay' ? loss.current : profitData.current,
                loss: backOrLay === 'lay' ? profit.current : loss.current,
                betRowNo: runnerRowDefault?.current,
                isfancy: betType === 'SESSION' ? 0 : 1,
                betType: betType,
                betAmount: stakeValue.current,
                rootClass: rootClassDefault.current
            };

            if (betType === 'fancy1') {
                data.loss = stakeValue.current;
            }
            if (backOrLay === 'lay') {
                data.profit = profit.current;
                data.loss = loss.current;

            }
            if (betType.toLowerCase() === 'bookmaker' && backOrLay === 'lay') {

            }

            axiosFetch('betStore', 'post', null, data)
                .then((data) => {


                    if (data.data.status === true) {
                        // setOddsTeamData(getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id))
                        individualBetPlaceFetch(teamname.current)
                        Notify(data.data.msg, null, null, 'success');

                        if (typeof callTeamDatas === 'function') {
                            const up = betType.toUpperCase()
                            const arrayBet = {
                                'ODDS': 'match_odds',
                                'BOOKMAKER': 'bookmaker',
                                'TIED_MATCH': 'tied match'
                            }

                            callTeamDatas({[up]: arrayBet[up]})
                        }

                    } else {


                        Notify(data.data.msg, null, null, 'danger');


                    }

                    getBalance()
                    resetAll()
                    clearAllSelection();

                }).catch((error) => {

                resetAll()
                clearAllSelection();

            })


        }

        if (betType === 'ODDS' || betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
            setHideLoading(true)

            submit()

        } else {
            submit()
        }

    };


    return (
        <BetPlacPopupSection teamNameCurrentBets={teamNameCurrentBets} setProfitValue={setProfitValue}
                             showCalculation={showCalculation} teamnames={teamNames}
                             betType={betType}
                             submitButtonDisable={submitButtonDisable} placeBet={placeBet}

                             teamname={teamname} backOrLay={backOrLay} sportList={sportList} match_id={match_id}
                             odds={odds} setOdds={setOdds} oddsk={oddsk} profitValue={profitValue} profit={profit}
                             loss={loss} clearAllSelection={clearAllSelection} handleStakeChange={handleStakeChange}
                             hideLoading={hideLoading}
                             profitData={profitData} rootClassDefault={rootClassDefault}
                             runnerRowDefault={runnerRowDefault} stakeValue={stakeValue}
        />
    );
};

export default BetPlacePopup;
