import React, {useContext, useEffect, useRef, useState} from "react";
import axiosFetch from "../../Common_Functions/Constants";
import RulesCards from "./RulesCards";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import MatchedBetTable from "../../Mobile/Section/Casino/MatchedBetTable";

const RightSideBarCasino = ({
                                sportList, myBetModel,
                                hideLoading,
                                teamname, isBack,
                                gamename, placeBet, submitButtonDisable, setOdds,
                                handleStakeChange, odds, stakeValue,
                                setSubmitButton = null


                            }) => {
    const [stakeValues, setStakeValues] = useState({});
    const {match_id} = useParams();
    const {
        popupDisplayForDesktop,
        setPopupDisplayForDesktop

    } = useContext(SportsContext)


    const currentStakeValue = useRef('');

    const handleCurrentStakeValue = (value) => {
        currentStakeValue.current.value = value

        if (typeof setSubmitButton === 'function') {
            if (value === '') {

                setSubmitButton(true)
            } else {
                setSubmitButton(false)
            }
        }
        handleStakeChange(value)
    }

    useEffect(() => {

        const getStakeValue = async () => {
            try {
                const response = await axiosFetch('get_stake_value', 'get');
                setStakeValues(response.data);
            } catch(error) {
                console.error("Error occurred while fetching stake value", error);
            }
        };
        setTimeout(getStakeValue, 5000)

    }, [stakeValues]);
    return (
        <div id="sidebar-right" className="col-md-3 sidebar-right">

            <div className="ps">
                <div className="sidebar-right-inner">
                    <div className="card m-b-10 place-bet">
                        {gamename === 'lottcard' && (
                            <div className="lottery-buttons">
                                <button className="btn btn-lottery">Repeat</button>
                                <button className="btn btn-lottery">Clear</button>
                                <button className="btn btn-lottery">Remove</button>
                            </div>
                        )}
                        {popupDisplayForDesktop === true && (
                            <>
                                <div className="card-header">
                                    <h6 className="card-title d-inline-block">Place Bet</h6>
                                </div>
                                <div className="betSlipBox">
                                    {hideLoading === false && (
                                        <div className="loader1 loderBet"></div>
                                    )}
                                    <div className={`table-responsive placeBetSilp ${isBack}`}>
                                        <form id="placeBetSilp">
                                            <table className="coupon-table table table-borderedless">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{width: "35%", textAlign: "left"}}>(Bet for)</th>
                                                    <th style={{width: "25%", textAlign: "left"}}>Odds</th>
                                                    <th style={{width: "15%", textAlign: "left"}}>Stake</th>
                                                    <th style={{width: "15%", textAlign: "left"}}>Profit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center"><span
                                                        onClick={() => setPopupDisplayForDesktop(false)}
                                                        className="text-danger"><i
                                                        className="fa fa-times"></i> </span></td>
                                                    <td id="ShowRunnderName">{teamname?.current}</td>
                                                    <td className="bet-odds">
                                                        <div className="form-group">
                                                            <input type="text" required="required"
                                                                   maxLength="4"
                                                                   value={odds}
                                                                   id="ShowBetPrice" readOnly="readonly"
                                                                   className="amountint"
                                                                   style={{width: "45px", verticalAlign: "middle"}}/>
                                                            <div
                                                                className="spinner-buttons input-group-btn btn-group-vertical">
                                                                <button type="button" onClick={() => {
                                                                    const newOdds = (parseFloat(odds) + 0.01).toFixed(2);
                                                                    setOdds(newOdds);
                                                                    handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                                                }}
                                                                        className="custom-btn-spinner btn btn-xs btn-default">
                                                                    <i className="fa fa-angle-up"></i></button>
                                                                <button type="button" onClick={() => {
                                                                    const newOdds = (parseFloat(odds) - 0.01).toFixed(2);
                                                                    setOdds(newOdds);
                                                                    handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                                                }}
                                                                        className="custom-btn-spinner btn btn-xs btn-default">
                                                                    <i className="fa fa-angle-down"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="bet-stakes">
                                                        <div className="form-group bet-stake">
                                                            <input maxLength="10" required="required" id="stakeValue"
                                                                   ref={currentStakeValue}

                                                                   onChange={(event) => handleCurrentStakeValue(event.target.value)}
                                                                   type="text"/>
                                                        </div>
                                                    </td>
                                                    <td className="text-right bet-profit" id="profitData"></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="5" className="value-buttons" style={{padding: "5px"}}>
                                                        {Object.entries(stakeValues).map(([index, value], i) => (
                                                            <button type="button"
                                                                    className="btn btn-secondary m-l-5 m-b-5"
                                                                    value={value.val} key={i}
                                                                    onClick={() => handleCurrentStakeValue(value.val)}>
                                                                {value.label}
                                                            </button>
                                                        ))}

                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="col-md-12">
                                                <button type="button" onClick={() => {
                                                    setPopupDisplayForDesktop(false);
                                                    if(typeof setSubmitButton === 'function')
                                                        setSubmitButton(true)
                                                }}
                                                        className="btn btn-sm btn-danger float-left">Reset
                                                </button>
                                                <button type="button" onClick={placeBet}
                                                        disabled={submitButtonDisable}
                                                        className="btn btn-sm btn-success float-right m-b-5">Submit
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="card m-b-10 my-bet">
                        <div className="card-header">
                            <h6 className="card-title d-inline-block">My Bet</h6>
                        </div>
                        <div className="card-body">
                            <MatchedBetTable type={sportList?.match_id} mybetModel={myBetModel}/>

                        </div>
                    </div>
                    <RulesCards gamename={match_id}/>

                </div>
            </div>
        </div>

    )
}
export default RightSideBarCasino;